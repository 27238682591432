// ==================================================
//  モバイル判定イベント
// ==================================================
function is_mobile() {
  const intFrameWidth = window.innerWidth;
  return intFrameWidth <= 767 ? true : false;
}

// ==================================================
//   ローディング アニメーション
// ==================================================
window.addEventListener("load", function () {
  const ctaFixed = document.querySelector(".js-ctaFixed");
  const pcHeader = document.querySelector(".js-pcHeader");
  if (pcHeader) {
    setTimeout(function () {
      pcHeader.classList.add("is-show");
    }, 1200);
  }
  if (ctaFixed) {
    setTimeout(function () {
      ctaFixed.classList.add("is-show");
    }, 1600);
  }
});

// ==================================================
//  ヘッダーメニューボタン（モバイル）
// ==================================================
const menu = document.querySelector(".js-menu");
const menuActiveClass = "is-open";
const menuButton = document.querySelector(".js-menuButton");
const menuButtonActiveClass = "is-open";
function headerMenuToggle() {
  menu.classList.toggle(menuActiveClass);
  menuButton.classList.toggle(menuButtonActiveClass);
}
menuButton.addEventListener("click", (event) => {
  headerMenuToggle();
});

// ==================================================
//  アンカーリンクのスムーススクロール
// ==================================================
// const anchors = document.querySelectorAll('a[href^="#"]');
// const headerHeight = document.querySelector("header").offsetHeight;
// const urlHash = location.hash;

// for (let i = 0; i < anchors.length; i++) {
//   anchors[i].addEventListener("click", (e) => {
//     e.preventDefault();

//     /* ==================================================
//      * ヘッダーメニューが開いていたら閉じる
//      */
//     if (menu.classList.contains(menuActiveClass)) {
//       headerMenuToggle();
//     }
//     /* ================================================== */

//     const href = anchors[i].getAttribute("href");
//     if (href !== "#top" && href !== "#") {
//       const target = document.getElementById(href.replace("#", ""));
//       const position = window.scrollY + target.getBoundingClientRect().top - headerHeight;
//       window.scroll({
//         top: position,
//         behavior: "smooth",
//       });
//     } else {
//       window.scroll({
//         top: 0,
//         behavior: "smooth",
//       });
//     }
//   });
// }

// ==================================================
//  アンカーリンクのページ遷移位置調整
// ==================================================
// window.addEventListener("load", (event) => {
//   const url = location.href;
//   let headerHeight = document.querySelector("header").offsetHeight;

//   if (url.indexOf("#") != -1) {
//     const anchor = url.split("#");
//     const position = Math.floor(document.getElementById(anchor[anchor.length - 1]).getBoundingClientRect().top + window.scrollY) - headerHeight;
//     window.scroll({
//       top: position,
//       behavior: "auto",
//     });
//   }
// });

// ==================================================
//  スクロールと連動したアニメーション
//   - HTMLに class="scrollAnimation-js" を定義してください。
//   - CSSにアニメーション変化後のクラス定義 scrollAnimation-js-active も必要です。
// ==================================================
// const callback = (entries) => {
//   entries.forEach((entry) => {
//     if (entry.isIntersecting) {
//       entry.target.classList.add("scrollAnimation-js-active");
//     }
//   });
// };
// const options = {
//   root: null,
//   rootMargin: "60% 0px -40% 0px",
//   threshold: 0,
// };
// let observer = new IntersectionObserver(callback, options);
// const animationItems = document.querySelectorAll(".scrollAnimation-js");
// animationItems.forEach((item) => {
//   observer.observe(item);
// });

// ==================================================
//  追従要素の横スクロール連動
// ==================================================
const fixedScroll = document.querySelector(".js-fixedScrollX");
window.addEventListener("scroll", () => {
  fixedScroll.style.left = -window.scrollX + "px";
});
